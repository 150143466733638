<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Informații Personale
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Modificați numele și alte informații</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn mr-2 btn-primary"
          @click="save()"
          ref="kt_save_changes"
        >
          Salvează
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Anulează
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Informații</h5>
          </div>
        </div>
<!--        <div class="form-group row">-->
<!--          <label class="col-xl-3 col-lg-3 col-form-label text-right"-->
<!--            >Avatar</label-->
<!--          >-->
<!--          <div class="col-lg-9 col-xl-6">-->
<!--            <div class="image-input image-input-outline" id="kt_profile_avatar">-->
<!--              <div-->
<!--                class="image-input-wrapper"-->
<!--                :style="{ backgroundImage: `url(${photo})` }"-->
<!--              ></div>-->
<!--              <label-->
<!--                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"-->
<!--                data-action="change"-->
<!--                data-toggle="tooltip"-->
<!--                title=""-->
<!--                data-original-title="Change avatar"-->
<!--              >-->
<!--                <i class="fa fa-pen icon-sm text-muted"></i>-->
<!--                <input-->
<!--                  type="file"-->
<!--                  name="profile_avatar"-->
<!--                  accept=".png, .jpg, .jpeg"-->
<!--                  @change="onFileChange($event)"-->
<!--                />-->
<!--                <input type="hidden" name="profile_avatar_remove" />-->
<!--              </label>-->
<!--              <span-->
<!--                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"-->
<!--                data-action="cancel"-->
<!--                data-toggle="tooltip"-->
<!--                title="Cancel avatar"-->
<!--              >-->
<!--                <i class="ki ki-bold-close icon-xs text-muted"></i>-->
<!--              </span>-->
<!--              <span-->
<!--                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"-->
<!--                data-action="remove"-->
<!--                data-toggle="tooltip"-->
<!--                title="Remove avatar"-->
<!--                @click="current_photo = null"-->
<!--              >-->
<!--                <i class="ki ki-bold-close icon-xs text-muted"></i>-->
<!--              </span>-->
<!--            </div>-->
<!--            <span class="form-text text-muted"-->
<!--              >Extensii acceptate: png, jpg, jpeg.</span-->
<!--            >-->
<!--          </div>-->
<!--        </div>-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Nume Prenume</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-bind:value="currentUserPersonalInfo.name"
            />
          </div>
        </div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">Informații de contact</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Telefon</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input
                ref="phone"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Phone"
                v-bind:value="currentUserPersonalInfo.phone"
              />
            </div>
            <span class="form-text text-muted"
              >Nu împărtășim datele dumneavoastră personale cu niciun
              terț.</span
            >
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Email</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                ref="email"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Email"
                v-bind:value="currentUserPersonalInfo.email"
              />
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";

export default {
  name: "PersonalInformation",
  data() {
    return {
      default_photo: "media/users/blank.png",
      current_photo: null
    };
  },
  mounted() {
    this.current_photo = this.currentUserPersonalInfo.photo;
  },
  methods: {
    save() {
      var name = this.$refs.name.value;
      var phone = this.$refs.phone.value;
      var email = this.$refs.email.value;
      var photo = this.photo;

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch(UPDATE_PERSONAL_INFO, {
          name: name,
          phone: phone,
          email: email,
          photo: photo
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {
      this.$refs.name.value = this.currentUserPersonalInfo.name;
      // this.$refs.surname.value = this.currentUserPersonalInfo.surname;
      this.$refs.phone.value = this.currentUserPersonalInfo.phone;
      this.$refs.email.value = this.currentUserPersonalInfo.email;
      this.current_photo = this.currentUserPersonalInfo.photo;
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    }
  }
};
</script>
